// Apply critical Safari fix first
import { fixSafariRegExp } from './safari-regexp-patch';
fixSafariRegExp();

// Create standalone loading indicator immediately (no external images needed)
const addLoadingIndicator = () => {
  // Create loader elements
  const loadingContainer = document.createElement('div');
  loadingContainer.id = 'oniichat-loader';
  
  // Add styles inline to ensure they're applied immediately
  loadingContainer.style.cssText = `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #2a2163 0%, #3b2a8c 100%);
    z-index: 9999;
    transition: opacity 0.5s ease-in-out;
  `;

  // Create SVG gem directly in the DOM (no external image needed)
  const svgNS = "http://www.w3.org/2000/svg";
  const svg = document.createElementNS(svgNS, "svg");
  svg.setAttribute("width", "120");
  svg.setAttribute("height", "120");
  svg.setAttribute("viewBox", "0 0 100 100");
  svg.style.cssText = `
    filter: drop-shadow(0 0 15px rgba(108, 92, 231, 0.6));
    animation: gem-pulse 1.5s infinite ease-in-out;
  `;

  // Create gem using SVG polygon
  const gem = document.createElementNS(svgNS, "polygon");
  gem.setAttribute("points", "50,10 90,40 90,60 50,90 10,60 10,40");
  gem.setAttribute("fill", "url(#gem-gradient)");
  gem.setAttribute("stroke", "rgba(255,255,255,0.3)");
  gem.setAttribute("stroke-width", "1");
  
  // Create gradient for gem
  const defs = document.createElementNS(svgNS, "defs");
  const gradient = document.createElementNS(svgNS, "linearGradient");
  gradient.setAttribute("id", "gem-gradient");
  gradient.setAttribute("x1", "0%");
  gradient.setAttribute("y1", "0%");
  gradient.setAttribute("x2", "100%");
  gradient.setAttribute("y2", "100%");
  
  const stop1 = document.createElementNS(svgNS, "stop");
  stop1.setAttribute("offset", "0%");
  stop1.setAttribute("stop-color", "#6c5ce7");
  
  const stop2 = document.createElementNS(svgNS, "stop");
  stop2.setAttribute("offset", "100%");
  stop2.setAttribute("stop-color", "#3498db");
  
  gradient.appendChild(stop1);
  gradient.appendChild(stop2);
  defs.appendChild(gradient);
  svg.appendChild(defs);
  svg.appendChild(gem);

  // Create loading text
  const loadingText = document.createElement('div');
  loadingText.textContent = 'Loading Oniichat...';
  loadingText.style.cssText = `
    color: white;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-top: 25px;
    opacity: 0.9;
  `;

  // Create progress bar
  const progressContainer = document.createElement('div');
  progressContainer.style.cssText = `
    width: 200px;
    height: 4px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    margin-top: 20px;
    overflow: hidden;
  `;

  const progressBar = document.createElement('div');
  progressBar.style.cssText = `
    height: 100%;
    width: 0%;
    background: linear-gradient(to right, #fc466b, #3f5efb);
    border-radius: 4px;
    transition: width 0.3s ease;
  `;
  progressBar.id = 'oniichat-progress-bar';

  // Add animation style
  const styleElement = document.createElement('style');
  styleElement.textContent = `
    @keyframes gem-pulse {
      0% {
        transform: scale(0.85);
        opacity: 0.8;
      }
      50% {
        transform: scale(1);
        opacity: 1;
      }
      100% {
        transform: scale(0.85);
        opacity: 0.8;
      }
    }
  `;

  // Assemble the loader elements
  progressContainer.appendChild(progressBar);
  loadingContainer.appendChild(svg);
  loadingContainer.appendChild(loadingText);
  loadingContainer.appendChild(progressContainer);
  document.head.appendChild(styleElement);
  document.body.appendChild(loadingContainer);

  // Mark the start of loading
  if (window.performance) {
    performance.mark('loading-start');
  }

  // Simulate progress faster
  let progress = 0;
  const interval = setInterval(() => {
    progress += Math.random() * 20; // Faster progress increment
    if (progress > 85) {
      clearInterval(interval);
      progress = 85; // Leave less final progress for faster perception
    }
    const progressElement = document.getElementById('oniichat-progress-bar');
    if (progressElement) {
      progressElement.style.width = `${progress}%`;
    }
  }, 200); // Shorter interval for faster updates

  return loadingContainer;
};

// Add the loading indicator immediately
const loadingIndicator = addLoadingIndicator();

// Function to remove the loading indicator
const removeLoadingIndicator = () => {
  if (loadingIndicator) {
    // Complete the progress bar
    const progressBar = document.getElementById('oniichat-progress-bar');
    if (progressBar) {
      progressBar.style.width = '100%';
    }
    
    // Mark loading end
    if (window.performance) {
      performance.mark('loading-end');
      performance.measure('loading-time', 'loading-start', 'loading-end');
    }
    
    // Fade out and remove after animation
    setTimeout(() => {
      loadingIndicator.style.opacity = '0';
      setTimeout(() => {
        if (loadingIndicator.parentNode) {
          loadingIndicator.parentNode.removeChild(loadingIndicator);
        }
      }, 300); // Faster fade out
    }, 300); // Faster transition
  }
};

// Dynamic import for React and ReactDOM to reduce initial bundle size
const renderApp = async () => {
  try {
    // Import React and ReactDOM asynchronously
    const [React, ReactDOMModule, AppProvidersModule] = await Promise.all([
      import('react'),
      import('react-dom/client'),
      import('./providers/AppProviders')
    ]);
    
    const ReactDOM = ReactDOMModule.default || ReactDOMModule;
    const { AppProviders } = AppProvidersModule;
    
    // Dynamically import App component
    const { default: App } = await import('./App');
    
    // Create root and render app
    const root = ReactDOM.createRoot(document.getElementById("root"));
    
    // Render the app with AppProviders
    root.render(
      <AppProviders>
        <App />
      </AppProviders>
    );
    
    // Load non-critical styles asynchronously after initial render
    setTimeout(() => {
      import('sanitize.css');
      import('regexp-polyfill');
      import('bootstrap/dist/css/bootstrap.min.css');
      import('@fortawesome/fontawesome-free/css/all.css');
      import('./tailwind.css');
      import('./styles/base/style.scss');
      import('./wdyr');
    }, 0);
    
    // Initialize Sentry later
    setTimeout(() => {
      import('./providers/SentryProvider').then(module => {
        try {
          module.initializeSentry();
        } catch (error) {
          console.error('Failed to initialize Sentry:', error);
        }
      }).catch(err => console.error('Failed to load Sentry provider:', err));
    }, 300);
    
  } catch (error) {
    console.error('Error initializing app:', error);
    
    // Show error in the loading indicator
    const loadingText = loadingIndicator.querySelector('div:not([id])');
    if (loadingText) {
      loadingText.textContent = 'Error loading application. Please refresh.';
      loadingText.style.color = '#ff6b6b';
    }
  }
};

// Start rendering immediately
renderApp().then(() => {
  // Start timing the app loading
  if (window.performance) {
    performance.mark('app-render-start');
  }
  
  // Remove loading indicator after initial content is visible
  setTimeout(removeLoadingIndicator, 500);
});

// Also try to remove when the page is fully loaded
window.addEventListener('load', () => {
  removeLoadingIndicator();
  
  // Report performance metrics
  if ('performance' in window) {
    try {
      performance.mark('app-fully-loaded');
      performance.measure('app-render-time', 'app-render-start', 'app-fully-loaded');
      
      const navEntries = performance.getEntriesByType('navigation');
      if (navEntries.length > 0) {
        const { loadEventEnd, responseEnd } = navEntries[0];
        console.log(`App loaded in ${loadEventEnd}ms (Time to Interactive: ${responseEnd}ms)`);
      }
      
      // Get all performance measures
      const measures = performance.getEntriesByType('measure');
      measures.forEach(measure => {
        console.log(`${measure.name}: ${measure.duration.toFixed(2)}ms`);
      });
    } catch (error) {
      console.error('Error reporting performance metrics:', error);
    }
  }
});

// Add visibility change handler to optimize for background tabs
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    // Prioritize loading when tab becomes visible
    removeLoadingIndicator();
  }
});