// Aggressively patch RegExp for Safari
export function fixSafariRegExp() {
  if (!/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) return;
  
  // Intercept regexp creation
  const originalRegExp = window.RegExp;
  window.RegExp = function(pattern, flags) {
    if (typeof pattern === 'string') {
      // Fix named capture groups
      pattern = pattern.replace(/\(\?<([^>]+)>/g, '(');
      // Fix lookbehinds
      pattern = pattern.replace(/\(\?<=/g, '(?:');
      pattern = pattern.replace(/\(\?<!/g, '(?:');
    }
    return new originalRegExp(pattern, flags);
  };
  window.RegExp.prototype = originalRegExp.prototype;
  
  console.log('Safari RegExp patched');
}